/* .Hero{
    color: var(--c-white);
    background-image: url(../assets/bg-hero-B.gif);
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;
    background-size: 55%;
    backdrop-filter: blur 10%;
    filter: blur(10px);
    z-index: -;
} */


/* Container with content and blurred GIF background */
.Hero {
    position: relative;
    width: 100%;
    
    overflow: hidden;
  }
  
  /* Blurred GIF background using ::before pseudo-element */
  .Hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url(../assets/bg-hero-B.gif);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;
    z-index: -1; /* Places the background behind the content */
    filter: blur(50px); 
    transform: scale(1.1); /* Slightly scale up to hide edges due to blur */
  }
  
  /* Example content inside the container */
  ._container {
    position: relative;
    z-index: 1; /* Ensure content stays above the background */
    color: white;
    font-size: 2rem;
    background-size: cover;
    text-align: center;
     /* Center content vertically */
  }
  
.hero-blob{
  /* background-image: url('../assets/bg-hero-A.png'); */

  

  /* background-size: 900px; */
  

}

/* ._container{
    position: relative;
    z-index: ;
} */



._ushan{
    font-size:16px ;
    font-weight: 300;
    color: var(--c-gray);

    
}
._kamesh{
    font-size:16px ;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    text-decoration: underline;
    color: var(--c-gray);
    
}

._box {
    border-radius: 27px;
    background-color: var(--c-dark) !important;
}

._box h2 {
    
    font-weight: 400;
    color: var(--c-gray);
}
._box p {
    
    font-weight: 700;
    color: var(--c-gray);
}

._brief h3{
    font-size: 32px;
    font-weight: 700;
    color: var(--c-white);
}
._brief p{
    font-size: 12px;
    font-weight: 300;
    color: var(--c-white);


}

._brief button a {

}


/* .card-shine-effect {
    @apply relative max-w-md overflow-hidden   ;
    background: linear-gradient(45deg, transparent 25%, rgba(214, 191, 255, 0.541) 50%, transparent 75%, transparent 100%);
    background-size: 250% 250%, 100% 100%;
    background-position: -100% 0, 0 0;
    background-repeat: no-repeat;
    transition: background-position 0s ease ,box-shadow 0.1s ease ;
    

  } */
  
  /* .card-shine-effect:hover {
    box-shadow: 0px 0px 20px -5px var(--c-white);
    background-position: 200% 0, 0 0;
    transition-duration: 1500ms;
  }
.card-shine-effect  {
    @apply relative    ;
    background: linear-gradient(45deg, transparent 25%, rgba(214, 191, 255, 0.274) 50%, transparent 75%, transparent 100%);
    background-size: 250% 250%, 100% 100%;
    background-position: -100% 0, 0 0;
    background-repeat: no-repeat;
    transition: background-position 0s ease ,box-shadow 0.1s ease ;
    border-radius: 24px;
    

  } */
  
  /* .card-shine-effect :hover {
    box-shadow: 0px 0px 20px -5px var(--c-white);
    background-position: 200% 0, 0 0;
    transition-duration: 1800ms;
  } */