._navBar {
    border-radius: 0 41px 41px 0px;
    box-shadow: 0px 0px 15px -2px var(--c-white);
    background-color: var(--c-dark);
    transition: padding-left 0.5s ease , padding-right 0.5s ease;
    


}

._navBar:hover{
    padding-left: 6px;
    padding-right: 6px;
}


._navigation a {
    font-size:16px;
    font-weight: 700;
    color: var(--c-gray);
    transition: text-decoration 0.6s ease;
}

._navigation a:hover {
    text-decoration: underline;
}


._social a img{
    filter: drop-shadow(0 0 3px #00000052);
    /* dro-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.288); */
}
._social a img:hover{
    filter: drop-shadow(0 0 5px var(--c-yellow));
    
}