.ButtonA {
    border-radius: 100px;
    box-shadow: 0px 0px 10px -2px var(--c-white);
    transition: box-shadow 0.3s ease;

}


.ButtonA p {
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;

}

.ButtonA:hover {
    
    box-shadow: 0px 0px 10px -2px var(--c-yellow);

}


.ButtonA p:hover {
    color: var(--c-yellow);
}