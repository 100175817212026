@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url(./components/color.css);


body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--c-dark);
  /* background-image: url('/src/assets/bg-hero-A.png'); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-shine-effect:hover {
  
  box-shadow: 0px 0px 20px -5px var(--c-white);
  background-position: 200% 0, 0 0;
  transition-duration: 1500ms;
}
.card-shine-effect  {
  background-color: var(--c-dark) !important;
  @apply relative    ;
  background: linear-gradient(45deg, transparent 25%, rgba(214, 191, 255, 0.274) 50%, transparent 75%, transparent 100%);
  background-size: 250% 250%, 100% 100%;
  background-position: -100% 0, 0 0;
  background-repeat: no-repeat;
  transition: background-position 0s ease ,box-shadow 0.1s ease ;
  
  

}